export function timeConverter(value: string) {
  // 2023-03-07T13:40:09.000Z

  const year = value.slice(0, 4);
  const month = value.slice(5, 7);
  const day = value.slice(8, 10);
  const hour = value.slice(11, 13);
  const minute = value.slice(14, 16);
  const second = value.slice(17, 19);

  return `${year}.${month}.${day} ${hour}:${minute}:${second}`;
}
