import { Navigate, Route, Routes } from "react-router-dom";
import { LPPUrls } from "../../../../LPPUrls";

import BisCreatePage from "./BisCreate";
import BisDetailPage from "./BisDetail";
import BisTablePage from "./BisTable";
import BisUpdatePage from "./BisUpdate";

export default function ProductBisRoot() {
  return (
    <Routes>
      <Route path={"/"} element={<BisTablePage />} />
      <Route
        path={LPPUrls.Admin.ProductBis.Create.pathName}
        element={<BisCreatePage />}
      />
      <Route
        path={LPPUrls.Admin.ProductBis.Update.pathName}
        element={<BisUpdatePage />}
      />
      <Route
        path={LPPUrls.Admin.ProductBis.Detail.pathName}
        element={<BisDetailPage />}
      />

      <Route path="/*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
}
