import { Navigate, Route, Routes } from "react-router-dom";
import { LPPUrls } from "../../../../LPPUrls";

import BisDetailPage from "./BisDetail";
import BisTablePage from "./BisTable";

export default function SettlementPaymentBisRoot() {
  return (
    <Routes>
      <Route path={"/"} element={<BisTablePage />} />

      <Route
        path={LPPUrls.Admin.SettlementPaymentBis.Detail.pathName}
        element={<BisDetailPage />}
      />

      <Route path="/*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
}
