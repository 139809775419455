import { Tag } from "antd";
import { GooglePlusOutlined } from "@ant-design/icons";

export const GoogleTag = () => {
  return (
    <Tag color="lime" className="m-1" icon={<GooglePlusOutlined />}>
      <span>
        <span className="text-[#00A1F1]">G</span>
        <span className="text-[#EA4335]">o</span>
        <span className="text-[#FFBB00]">o</span>
        <span className="text-[#00A1F1]">g</span>
        <span className="text-[#34A853]">l</span>
        <span className="text-[#EA4335]">e</span>
      </span>
    </Tag>
  );
};
