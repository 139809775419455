import { Descriptions, Result, Image, Collapse, Space, Button } from "antd";
import Breadcrumb from "antd/es/breadcrumb/Breadcrumb";
import useNotification from "antd/es/notification/useNotification";
import Title from "antd/es/typography/Title";
import { AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HContent } from "../../../components/common/HContent";
import Header from "../../../components/common/Header";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";
import { DetailTheme } from "./type";

const { Panel } = Collapse;

export default function DetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any | AxiosError | null>(null);
  const [api, contextHolder] = useNotification();

  const [data, setData] = useState<DetailTheme>();
  const onClickBack = () => {
    navigate(LPPUrls.Admin.BackgroundThemeManagement.Main.url());
  };
  const onClickUpdate = () => {
    navigate(LPPUrls.Admin.BackgroundThemeManagement.Update.url(id!));
  };

  const onClickDelete = () => {
    setLoading(true);
    LPPAXIOS.post(`/admin/theme/delete`, {
      ids: [id],
    })
      .then((resolve) => {
        api.success({
          message: "곡이 삭제되었습니다.",
        });
        setTimeout(() => {
          navigate(LPPUrls.Admin.BackgroundThemeManagement.Main.url());
        }, 600);
      })
      .catch((error) => {
        api.error({
          message: "곡을 삭제할 수 없습니다.",
        });
      })
      .finally(() => setLoading(false));
  };

  useLayoutEffect(() => {
    setLoading(true);
    LPPAXIOS.get(`/admin/theme/${id}`)
      .then((resolve) => {
        setData(resolve.data);
      })
      .catch((error) => {
        // if (axios.isAxiosError(error)) {
        //   return setError(error);
        // }
        return setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);
  if (error !== null && error.response.status === 404)
    return (
      <Result
        status={404}
        title={"404"}
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );
  if (error !== null && error.response.status === 500)
    return (
      <Result
        status={500}
        title={"500"}
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );
  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm">
        <Title level={4}>배경테마 상세</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.BackgroundThemeManagement.Main.url()}>
              배경테마 조회
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>배경테마 상세페이지</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Space className="flex justify-end w-full">
          <Button onClick={onClickUpdate}>수정</Button>
          <Button onClick={onClickDelete} danger>
            삭제
          </Button>
        </Space>
        <Image
          src={`${process.env.REACT_APP_IMG_ENDPOINT}/${data?.artwork}`}
          width={300}
        />

        <audio
          controls
          src={`${process.env.REACT_APP_IMG_ENDPOINT}/${data?.url}`}
        />
        <Descriptions column={2} bordered>
          <Descriptions.Item label="음원 생성일">
            {data?.createdAt}
          </Descriptions.Item>
        </Descriptions>
        <Collapse defaultActiveKey={["ko"]} collapsible="header">
          <Panel header="한국어" key="ko">
            <p>이름:{data?.titleKo}</p>
          </Panel>
          <Panel header="영어" key="en">
            <p>이름:{data?.titleEn}</p>
          </Panel>
        </Collapse>
      </HContent>
    </>
  );
}
