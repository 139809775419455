interface UrlFilterOptions {
  url: string;
  page: number | string;
  perPage: number | string;
  keyword?: string | null;
  sort?: string | null;
  order?: string | null;
  type?: string | null;
  status?: string | null;
}
interface NavFilterOptions {
  keyword?: string | null;
  sort?: string | null;
  order?: string | null;
  type?: string | null;
  status?: string | null;
}

export const urlFilter = ({
  url,
  page,
  perPage,
  keyword = null,
  sort = null,
  order = null,
  type = null,
  status = null,
}: UrlFilterOptions) => {
  let _url = url;
  _url += `?page=${page}`;
  _url += `&perPage=${perPage}`;
  _url +=
    keyword === null || keyword === "" || keyword === undefined
      ? ""
      : `&keyword=${keyword}`;
  _url += sort === null ? "" : `&sort=${sort}`;
  _url += order === null ? "" : `&order=${order}`;
  _url += type === null ? "" : `&type=${type}`;
  _url += status === null ? "" : `&status=${status}`;
  return _url;
};

export const navFilter = ({
  keyword = null,
  sort = null,
  order = null,
  type = null,
  status = null,
}: NavFilterOptions) => {
  let _url = "";
  _url +=
    keyword === null || keyword === "" || keyword === undefined
      ? ""
      : `&keyword=${keyword}`;
  _url += sort === null ? "" : `&sort=${sort}`;
  _url += order === null ? "" : `&order=${order}`;
  _url += type === null ? "" : `&type=${type}`;
  _url += status === null ? "" : `&status=${status}`;
  return _url;
};

interface paramsFilterOptions {
  [key: string]: number | string | null;
}

export const paramsFilter = (options: paramsFilterOptions) => {
  let _url = "";

  Object.entries(options).forEach(([key, value]) => {
    if (value !== null) {
      _url += `&${key}=${value}`;
    }
  });

  return _url;
};

interface Filter {
  [key: string]: string[] | null;
}

export function filterNonEmptyProperties(filter: Filter): {
  [key: string]: string | null;
} {
  const result: { [key: string]: string | null } = {};

  Object.entries(filter).forEach(([key, value]) => {
    if (Array.isArray(value) && value.length > 0) {
      result[key] = value[0];
    }
  });

  return result;
}
