import { Tag } from "antd";
import { MailOutlined } from "@ant-design/icons";

export const EmailAccountTag = () => {
  return (
    <Tag className="m-1" icon={<MailOutlined />}>
      Email
    </Tag>
  );
};
