export interface CategoryType {
  label: string;
  value: number;
}
export const categoryListData: CategoryType[] = [
  { label: "생각 비우기", value: 1 },
  { label: "수면/숙면", value: 2 },
  { label: "식탐감소", value: 3 },
  { label: "집중력 향상", value: 4 },
  { label: "스트레스 완화", value: 5 },
  { label: "간접체험", value: 6 },
  { label: "선택안함.", value: 7 },
];

export interface LangaugeType {
  label: string;
  value: string;
}
export const langaugeListData: LangaugeType[] = [
  { label: "한국어", value: "ko" },
  { label: "영어", value: "en" },
];

export function findCategory(value: number) {
  switch (value) {
    case 1:
      return "minute_miracle";
    case 2:
      return "sleep";
    case 3:
      return "diet";
    case 4:
      return "concentration";
    case 5:
      return "stress";
    case 6:
      return "experience";
    case 7:
      return null;
    default:
      // Optionally handle any other cases or provide a default
      return null;
  }
}

export function findCategoryName(id: string) {
  const idNum = Number(id);
  const name = categoryListData.find((item) => item.value === idNum);
  if (name) {
    return name.label;
  } else {
    return "Null";
  }
}
