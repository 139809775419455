// export function timeConverter(value: string) {
//   // 2023-03-07T13:40:09.000Z

//   const year = value.slice(0, 4);
//   const month = value.slice(5, 7);
//   const day = value.slice(8, 10);
//   const hour = value.slice(11, 13);
//   const minute = value.slice(14, 16);
//   const second = value.slice(17, 19);

//   return `${year}.${month}.${day} ${hour}:${minute}:${second}`;
// }

// export function timeConverter(value: string) {
//   const utcDate = new Date(value); // Parse the UTC timestamp

//   // Convert to UTC+9 timezone
//   const options = { timeZone: "Asia/Seoul", hour12: false };
//   const koreanDate = utcDate.toLocaleString("en-US", options);

//   return koreanDate.replace(",", ""); // Remove the comma from the result
// }
export function timeConverter(value: string) {
  const utcDate = new Date(value); // Parse the UTC timestamp

  // Convert to UTC+9 timezone
  const koreanDate = new Date(utcDate.valueOf() + 9 * 60 * 60 * 1000); // Add 9 hours

  const year = koreanDate.getUTCFullYear();
  const month = String(koreanDate.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(koreanDate.getUTCDate()).padStart(2, "0");
  const hour = String(koreanDate.getUTCHours()).padStart(2, "0");
  const minute = String(koreanDate.getUTCMinutes()).padStart(2, "0");
  const second = String(koreanDate.getUTCSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}
