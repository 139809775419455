import { Tag } from "antd";

export const KakaoTag = () => {
  return (
    <Tag
      color="#FEE500"
      className="flex justify-center m-1 w-[75px] text-[#191919] font-bold"
    >
      Kakao
    </Tag>
  );
};
