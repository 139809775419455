import {
  Button,
  Input,
  notification,
  Pagination,
  PaginationProps,
  Popconfirm,
  Space,
  Table,
  Typography,
} from "antd";

import { Content } from "antd/es/layout/layout";
import { ColumnsType } from "antd/es/table";
import axios from "axios";
import { Key, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Header from "../../../components/common/Header";

import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";
import { timeConverter } from "../../../utils/dateTimeConveter";
import { preventSpaceBar } from "../../../utils/inputOnlyNumber";

import { navFilter, urlFilter } from "../../../utils/paramsFilter";
import { AlbumTable } from "./type";

const { Title } = Typography;

export default function TablePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const keyword = params.get("keyword");
  const order = params.get("order");
  const sort = params.get("sort");
  const type = params.get("type");

  const [data, setData] = useState<AlbumTable[]>([]);
  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const TableColumn: ColumnsType<AlbumTable> = [
    {
      title: "앨범 이름",
      dataIndex: "nameKo",
      sorter: true,
      defaultSortOrder:
        sort === "nameKo" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "곡 개수",
      dataIndex: "trackCount",
      sorter: true,
      defaultSortOrder:
        sort === "trackCount" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "생성날짜",
      dataIndex: "createdAt",
      render: (_, record) => timeConverter(record.createdAt),
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "createdAt" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: (
        <Popconfirm
          disabled={selectedRowKeys.length === 0}
          title="앨범 삭제"
          description="앨범을 삭제하시겠습니까?"
          onConfirm={(e: any) => {
            handleDeleteAll();
          }}
          onCancel={(e: any) => {}}
          okText="Yes"
          cancelText="No"
        >
          <Button danger disabled={selectedRowKeys.length === 0}>
            삭제
          </Button>
        </Popconfirm>
      ),
      render: (_, record) => (
        <>
          <Space>
            <Button
              size="small"
              onClick={(e) => {
                e.stopPropagation(); // Stop event propagation
                // Handle edit logic here
                handleEdit(record);
              }}
            >
              수정
            </Button>
            <Popconfirm
              title="앨범 삭제"
              description="앨범을 삭제하시겠습니까?"
              onConfirm={(e: any) => {
                e.stopPropagation(); // Stop event propagation
                handleDelete(record);
              }}
              onCancel={(e: any) => {
                e.stopPropagation(); // Stop event propagation
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                danger
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                삭제
              </Button>
            </Popconfirm>
          </Space>
        </>
      ),
    },
  ];

  const handleEdit = (record: AlbumTable) => {
    // Handle edit action here
    navigate(LPPUrls.Admin.Albums.Update.url(record.id));
  };

  /** 개별 삭제 */
  const handleDelete = (record: AlbumTable) => {
    // Handle delete action here
    LPPAXIOS.post(`/admin/situation/album/delete`, {
      situationIds: [record.id],
    })
      .then((resolve) => {
        api.success({
          message: "앨범이 삭제되었습니다.",
        });
        setData((prev) => prev.filter((item) => item.id !== record.id));
        // window.location.reload();
      })
      .catch((error) => {
        api.error({
          message: "앨범이 삭제 실패",
        });
      });
  };

  const handleDeleteAll = () => {
    // Handle delete action here
    LPPAXIOS.post(`/admin/situation/album/delete`, {
      situationIds: selectedRowKeys,
    })
      .then((resolve) => {
        api.success({
          message: "앨범이 삭제되었습니다.",
        });
        window.location.reload();
      })
      .catch((error) => {
        api.error({
          message: "앨범이 삭제 실패",
        });
      });
  };

  const handleTableChange = (value: any, filter: any, sorter: any) => {
    let order =
      sorter.order === null ? null : sorter.order === "ascend" ? "ASC" : "DESC";
    let sort = sorter && sorter.field ? sorter.field : null;

    let type =
      Array.isArray(filter["type"]) && filter["type"].length
        ? filter["type"][0]
        : null;

    handleChangeSort(sort, order, type);
  };

  const handleChangeSort = (
    sort: null | string,
    order: null | string,
    type: null | string
  ) => {
    navigate(
      LPPUrls.Admin.Albums.Main.url(
        currentPage,
        navFilter({ keyword: keyword, sort: sort, order: order, type: type })
      )
    );
    setCurrentPage(currentPage);
  };

  const handleOnSearchFilter = (value: string) => {
    navigate(
      LPPUrls.Admin.Albums.Main.url(
        1,
        navFilter({ keyword: value, sort: sort, order: order, type: type })
      )
    );
    setCurrentPage(1);
  };
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      LPPUrls.Admin.Albums.Main.url(
        page,
        navFilter({ keyword: keyword, sort: sort, order: order, type: type })
      )
    );
    setCurrentPage(page);
  };

  const onClickCreateAlbum = () => {
    navigate(LPPUrls.Admin.Albums.Create.pathName);
  };
  useLayoutEffect(() => {
    setLoading(true);
    LPPAXIOS.get(
      urlFilter({
        url: `/admin/situation/album`,
        page: currentPage,
        perPage: 25,
        keyword: keyword,
        sort: sort,
        order: order,
        type: type,
      })
    )
      .then((res) => {
        setData(res.data.list);
        setTotal(res.data.list.length);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: `Notification ${error.code}`,
            description: `${error.message}`,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [api, currentPage, order, sort, type, keyword]);
  return (
    <>
      {contextHolder}
      <Header className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm">
        <Title level={4}>앨범 조회</Title>
      </Header>
      <Content className="m-2 bg-white">
        <Table
          rowClassName={() => "cursor-pointer"}
          loading={loading}
          pagination={false}
          rowKey={(render) => render.id}
          dataSource={data}
          columns={TableColumn}
          onChange={(val: any, filter: any, sorter: any, extra: any) => {
            handleTableChange(val, filter, sorter);
          }}
          onRow={(record, _) => {
            return {
              onClick: () => {
                navigate(LPPUrls.Admin.Albums.Detail.url(record.id));
              }, // click row
            };
          }}
          rowSelection={{
            selectedRowKeys,
            onChange: (selectedKeys) => {
              setSelectedRowKeys(selectedKeys);
            },
          }}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <Space className="flex items-center">
                <Title level={4}>앨범 리스트</Title>
                <Input.Search
                  defaultValue={keyword ?? ""}
                  style={{ width: 300 }}
                  placeholder="앨범의 이름 또는 태그를 검색하세요."
                  onSearch={handleOnSearchFilter}
                  onInput={preventSpaceBar}
                />
              </Space>
              <Button onClick={onClickCreateAlbum}>앨범 생성</Button>
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
        />
      </Content>
    </>
  );
}
