import {
  Button,
  Descriptions,
  Popconfirm,
  Result,
  Skeleton,
  Space,
  Tag,
} from "antd";
import Breadcrumb from "antd/es/breadcrumb/Breadcrumb";

import Title from "antd/es/typography/Title";
import { AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HContent } from "../../../../components/common/HContent";
import Header from "../../../../components/common/Header";

import { LPPAXIOS } from "../../../../framework/api/core.api";
import { LPPUrls } from "../../../../LPPUrls";
import { timeConverter } from "../../../../utils/dateTimeConveter";

// import * as ExcelJS from "exceljs";
// import { saveAs } from "file-saver";
import { CollaborateProductDetail } from "./type";
import useNotification from "antd/es/notification/useNotification";

export default function DetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any | AxiosError | null>(null);
  const [api, contextHolder] = useNotification();

  const [data, setData] = useState<CollaborateProductDetail>();
  const onClickBack = () => {
    navigate(LPPUrls.Admin.ProductBis.Main.url());
  };

  // const handleExcel = async () => {
  //   const workbook = new ExcelJS.Workbook();
  //   const userSheet = workbook.addWorksheet("상품 정보");

  //   userSheet.columns = [
  //     { header: "Id", key: "id", width: 10 },
  //     { header: "닉네임", key: "collaboratorName", width: 32 },
  //     { header: "이메일", key: "email", width: 32 },
  //     { header: "유저상태", key: "userStatus", width: 32 },
  //     { header: "가입일", key: "createdAt", width: 20 },
  //   ];

  //   userSheet.addRow({
  //     id: data?.id,
  //     nickname: data?.collaboratorName,
  //     email: data?.email,
  //     userStatus: data?.userStatus,
  //     createdAt: data?.createdAt
  //       ? timeConverter(data.createdAt).slice(0, 10)
  //       : "",
  //   });

  //   // 다운로드
  //   const mimeType = {
  //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //   };
  //   const buffer = await workbook.xlsx.writeBuffer();
  //   const blob = new Blob([buffer], mimeType);
  //   saveAs(blob, `${data?.collaboratorName} 상품 상세.xlsx`);
  // };

  const onClickUpdate = () => {
    navigate(LPPUrls.Admin.ProductBis.Update.url(id!));
  };

  const onClickDelete = () => {
    setLoading(true);
    LPPAXIOS.delete(`/admin/collaborateProduct/${id}`)
      .then((resolve) => {
        api.success({
          message: "B2B 상품이 삭제되었습니다.",
          description: "B2B 상품 조회 페이지로 이동합니다.",
        });
        setTimeout(() => {
          navigate(LPPUrls.Admin.ProductBis.Main.url());
        }, 600);
      })
      .catch((error) => {
        api.error({
          message: "B2B 상품 삭제 실패",
          description: error.response.data.message ?? "",
        });
      })
      .finally(() => setLoading(false));
  };

  useLayoutEffect(() => {
    LPPAXIOS.get<CollaborateProductDetail>(`/admin/collaborateProduct/${id}`)
      .then((resolve) => {
        setData(resolve.data);
      })
      .catch((error) => {
        return setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);
  if (error !== null && error.response.status === 404)
    return (
      <Result
        status={404}
        title={"404"}
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );
  if (error !== null && error.response.status === 500)
    return (
      <Result
        status={500}
        title={"500"}
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );
  return (
    <>
      {contextHolder}
      <Header className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm">
        <Title level={4}>B2B 상품 상세조회</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.ProductBis.Main.url()}>B2B 상품 조회</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>B2B 상품 상세페이지</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        {loading ? (
          <Skeleton active={loading} />
        ) : (
          <Descriptions
            bordered
            column={1}
            title={
              <Space className="flex justify-between w-full">
                {/* <Button onClick={handleExcel}>엑셀 다운로드</Button> */}
                <Space>
                  <Button type="primary" onClick={onClickUpdate}>
                    수정
                  </Button>
                  <Popconfirm
                    title={"협업 회사 삭제"}
                    onConfirm={onClickDelete}
                  >
                    <Button danger>삭제</Button>
                  </Popconfirm>
                </Space>
              </Space>
            }
          >
            <Descriptions.Item label="회사명">
              {data?.collaboratorName}
            </Descriptions.Item>
            <Descriptions.Item label="이메일">{data?.email}</Descriptions.Item>
            <Descriptions.Item label="연락처">
              {data?.contact}
            </Descriptions.Item>
            <Descriptions.Item label="상품명">{data?.nameKo}</Descriptions.Item>
            <Descriptions.Item label="상품설명">
              {data?.descriptionKo}
            </Descriptions.Item>
            <Descriptions.Item label="상품가격">
              {data?.price}
            </Descriptions.Item>
            <Descriptions.Item label="구독기간(일)">
              {data?.duration}일
            </Descriptions.Item>
            <Descriptions.Item label="사용,등록 가능 기간(일)">
              {data?.activeDuration}일
            </Descriptions.Item>
            <Descriptions.Item label="취소,환불 가능 기간(일)">
              {data?.cancelDuration === 0 ? (
                <Tag color="red">환불 불가 상품</Tag>
              ) : (
                `${data?.cancelDuration}일`
              )}
            </Descriptions.Item>

            <Descriptions.Item label="발행일자">
              {data?.createdAt ? timeConverter(data.createdAt) : ""}
            </Descriptions.Item>
            <Descriptions.Item label="상품상태">
              {data?.enable ? (
                <Tag color="green">활성</Tag>
              ) : (
                <Tag color="red">비활성</Tag>
              )}
            </Descriptions.Item>
          </Descriptions>
        )}
      </HContent>
    </>
  );
}
