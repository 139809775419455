import {
  Input,
  notification,
  Pagination,
  PaginationProps,
  Table,
  Tag,
  Typography,
  DatePicker,
} from "antd";

import { Content } from "antd/es/layout/layout";
import { ColumnsType } from "antd/es/table";
import axios from "axios";
import { useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../../../components/common/Header";
import { LPPAXIOS } from "../../../../framework/api/core.api";
import { LPPUrls } from "../../../../LPPUrls";
import { preventSpaceBar } from "../../../../utils/inputOnlyNumber";
import {
  filterNonEmptyProperties,
  paramsFilter,
} from "../../../../utils/paramsFilter";
import { RESPONSETABLE, STATUS, TableOrder } from "./type";
import { timeConverter } from "../../../../utils/dateTimeConveter";
const { RangePicker } = DatePicker;
const { Title } = Typography;

export default function BisTablePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const keyword = params.get("keyword");
  const order = params.get("order");
  const sort = params.get("sort");
  const status = params.get("status");
  const startDate = params.get("startDate");
  const endDate = params.get("endDate");

  const [data, setData] = useState<TableOrder[]>([]);
  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const TableColumn: ColumnsType<TableOrder> = [
    {
      title: "주문번호",
      dataIndex: "orderNumber",
      fixed: true,
      sorter: true,
      defaultSortOrder:
        sort === "orderNumber"
          ? order === "ASC"
            ? "ascend"
            : "descend"
          : null,
    },
    {
      title: "B2B 업체",
      dataIndex: "collaboratorName",
      fixed: true,
    },
    {
      title: "B2B 상품명",
      dataIndex: "productNameKo",
      fixed: true,
    },
    {
      title: "수량",
      dataIndex: "amount",
      sorter: true,
      defaultSortOrder:
        sort === "amount" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "주문날짜",
      dataIndex: "orderDate",
    },
    {
      title: "무료체험 여부",
      dataIndex: "isFirstYn",
    },
    {
      title: "상태",
      dataIndex: "status",
      render: (_, record) => {
        if (record.status === STATUS.CANCELLED) {
          return <Tag color="red">결제취소</Tag>;
        }
        if (record.status === STATUS.PAID) {
          return <Tag color="blue">결제</Tag>;
        }
        if (record.status === STATUS.COMFIRMED) {
          return <Tag color="green">확인</Tag>;
        }
        if (record.status === STATUS.REFUNDED) {
          return <Tag color="orange">환불</Tag>;
        }
      },
      filters: [
        {
          text: <Tag color="red">결제취소</Tag>,
          value: STATUS.CANCELLED,
        },
        {
          text: <Tag color="blue">결제</Tag>,
          value: STATUS.PAID,
        },
        {
          text: <Tag color="green">확인</Tag>,
          value: STATUS.COMFIRMED,
        },
        {
          text: <Tag color="orange">환불</Tag>,
          value: STATUS.REFUNDED,
        },
      ],
      filterMultiple: false,
      defaultFilteredValue: status === null ? [] : [status],
    },
    {
      title: "B2B 업체 ID",
      dataIndex: "mberNo",
    },
    {
      title: "주문자",
      dataIndex: "mberNm",
    },
    {
      title: "주문자 연락처",
      dataIndex: "mberHpNo",
    },
    {
      title: "생성된 쿠폰 ID",
      dataIndex: "couponId",
    },

    {
      title: "주문 취소 일자",
      dataIndex: "cancelDate",
    },
    {
      title: "취소사유",
      dataIndex: "cancelReason",
    },
    {
      title: "주문 취소 마감 기한",
      dataIndex: "cancellationExpiresAt",
      render: (_, record) => timeConverter(record.cancellationExpiresAt),
    },
  ];

  const handleTableChange = (value: any, filter: any, sorter: any) => {
    let order =
      sorter.order === null ? null : sorter.order === "ascend" ? "ASC" : "DESC";
    let sort = sorter.field ?? null;
    let result = filterNonEmptyProperties(filter);

    handleChangeSort(sort, order, result);
  };

  const handleChangeSort = (
    sort: null | string,
    order: null | string,
    result: { [key: string]: string | null }
  ) => {
    navigate(
      LPPUrls.Admin.SettlementPaymentBis.Main.url(
        currentPage,
        paramsFilter({
          keyword: keyword,
          sort: sort,
          order: order,
          ...result,
          startDate: startDate,
          endDate: endDate,
        })
      )
    );
    setCurrentPage(currentPage);
  };

  const handleOnSearchFilter = (value: string) => {
    navigate(
      LPPUrls.Admin.SettlementPaymentBis.Main.url(
        1,
        paramsFilter({
          keyword: value,
          sort: sort,
          order: order,
          status: status,
          startDate: startDate,
          endDate: endDate,
        })
      )
    );
    setCurrentPage(1);
  };

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      LPPUrls.Admin.SettlementPaymentBis.Main.url(
        page,
        paramsFilter({
          keyword: keyword,
          sort: sort,
          order: order,
          status: status,
          startDate: startDate,
          endDate: endDate,
        })
      )
    );
    setCurrentPage(page);
  };
  const handleOnChangeRangePicker = (value: null | [any, any]) => {
    if (value === null) {
      return;
    }
    const startDate = value[0].format("YYYY-MM-DD");
    const endDate = value[1].format("YYYY-MM-DD");
    navigate(
      LPPUrls.Admin.SettlementPaymentBis.Main.url(
        1,
        paramsFilter({
          keyword: keyword,
          sort: sort,
          order: order,
          status: status,
          startDate: startDate,
          endDate: endDate,
        })
      )
    );
  };

  useLayoutEffect(() => {
    setLoading(true);
    LPPAXIOS.get<RESPONSETABLE<TableOrder>>(
      `/admin/collaborator/orderList?` +
        paramsFilter({
          page: currentPage,
          perPage: 25,
          keyword: keyword,
          sort: sort,
          order: order,
          status: status,
          startDate: startDate,
          endDate: endDate,
        })
    )
      .then((res) => {
        setData(res.data.list);
        setTotal(res.data.total);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: `Notification ${error.code}`,
            description: `${error.message}`,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [api, currentPage, order, sort, status, keyword, startDate, endDate]);
  return (
    <>
      {contextHolder}
      <Header className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm">
        <Title level={4}>B2B 주문 관리</Title>
      </Header>
      <Content className="m-2 bg-white">
        <Table
          rowClassName={() => "cursor-pointer"}
          loading={loading}
          pagination={false}
          rowKey={(render, idx) => render.orderNumber + idx}
          dataSource={data}
          columns={TableColumn}
          onChange={(val: any, filter: any, sorter: any, extra: any) => {
            handleTableChange(val, filter, sorter);
          }}
          scroll={{ x: 2000 }}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <Input.Search
                defaultValue={keyword ?? ""}
                style={{ width: 300 }}
                placeholder="주문번호를 입력하세요."
                onSearch={handleOnSearchFilter}
                onInput={preventSpaceBar}
              />
              <RangePicker onChange={handleOnChangeRangePicker} />
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
        />
      </Content>
    </>
  );
}
