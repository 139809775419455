import { Navigate, Route, Routes } from "react-router-dom";
import { LPPUrls } from "../../../LPPUrls";
import CreatePage from "./Create";
import DetailPage from "./Detail";
import SoundTablePage from "./SoundTable";
import TablePage from "./Table";
import UpdatePage from "./Update";

export default function CategoryRoot() {
  return (
    <Routes>
      <Route path="/" element={<TablePage />} />

      <Route
        path={LPPUrls.Admin.Category.Detail.pathName}
        element={<DetailPage />}
      />
      <Route
        path={LPPUrls.Admin.Category.Create.pathName}
        element={<CreatePage />}
      />
      <Route
        path={LPPUrls.Admin.Category.Update.pathName}
        element={<UpdatePage />}
      />
      <Route
        path={LPPUrls.Admin.Category.CategorySounds.pathName}
        element={<SoundTablePage />}
      />

      <Route path="/*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
}
