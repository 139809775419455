import { Button, Descriptions, Divider, Result, Table } from "antd";
import Breadcrumb from "antd/es/breadcrumb/Breadcrumb";
import { ColumnsType } from "antd/es/table";
import Title from "antd/es/typography/Title";
import { AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HContent } from "../../../components/common/HContent";
import Header from "../../../components/common/Header";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";
import { timeConverter } from "../../../utils/dateTimeConveter";
import { Subscription, USERDETAILTYPE } from "./type";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const SubscriptionColumn: ColumnsType<Subscription> = [
  {
    title: "시작일",
    dataIndex: "startDate",
    render: (_, record) => timeConverter(record.startDate).slice(0, 10),
  },
  {
    title: "종료일",
    dataIndex: "endDate",
    render: (_, record) => timeConverter(record.endDate).slice(0, 10),
  },
  {
    title: "상태",
    dataIndex: "status",
  },
];

export default function DetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any | AxiosError | null>(null);

  const [data, setData] = useState<USERDETAILTYPE>();
  const onClickBack = () => {
    navigate(LPPUrls.Admin.Notice.Main.url());
  };

  const handleExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const userSheet = workbook.addWorksheet("유저 정보");
    const subSribSheet = workbook.addWorksheet("구독 정보");

    userSheet.columns = [
      { header: "Id", key: "id", width: 10 },
      { header: "닉네임", key: "nickname", width: 32 },
      { header: "이메일", key: "email", width: 32 },
      { header: "유저상태", key: "userStatus", width: 32 },
      { header: "가입일", key: "createdAt", width: 20 },
    ];

    subSribSheet.columns = [
      { header: "시작일", key: "startDate", width: 32 },
      { header: "종료일", key: "endDate", width: 32 },
      { header: "상태", key: "status", width: 32 },
    ];

    userSheet.addRow({
      id: data?.id,
      nickname: data?.nickName,
      email: data?.email,
      userStatus: data?.userStatus,
      createdAt: data?.createdAt
        ? timeConverter(data.createdAt).slice(0, 10)
        : "",
    });

    data?.list.forEach((item, index) => {
      subSribSheet.addRow({
        startDate: item.startDate
          ? timeConverter(item.startDate).slice(0, 10)
          : "",
        endDate: item.endDate ? timeConverter(item.endDate).slice(0, 10) : "",
        status: item.status,
      });
    });

    // 다운로드
    const mimeType = {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    };
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], mimeType);
    saveAs(blob, `${data?.nickName} 회원상세.xlsx`);
  };

  useLayoutEffect(() => {
    setLoading(true);
    LPPAXIOS.get<USERDETAILTYPE>(`/admin/users/${id}/detail`)
      .then((resolve) => {
        setData(resolve.data);
      })
      .catch((error) => {
        return setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);
  if (error !== null && error.response.status === 404)
    return (
      <Result
        status={404}
        title={"404"}
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );
  if (error !== null && error.response.status === 500)
    return (
      <Result
        status={500}
        title={"500"}
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );
  return (
    <>
      <LoadingSpin loading={loading} />
      <Header className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm">
        <Title level={4}>사용자 상세조회</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Users.Main.url()}>사용자 조회</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>사용자 상세페이지</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Descriptions
          bordered
          title={<Button onClick={handleExcel}>엑셀 다운로드</Button>}
        >
          <Descriptions.Item label="닉네임" span={3}>
            {data?.nickName}
          </Descriptions.Item>
          <Descriptions.Item label="유저상태" span={3}>
            {data?.userStatus}
          </Descriptions.Item>
          <Descriptions.Item label="이메일" span={3}>
            {data?.email}
          </Descriptions.Item>

          <Descriptions.Item label="가입일자">
            {data?.createdAt ? timeConverter(data.createdAt) : ""}
          </Descriptions.Item>
        </Descriptions>

        <Divider>구독 기록</Divider>
        <Table
          dataSource={data?.list ? data.list : []}
          columns={SubscriptionColumn}
        />
      </HContent>
    </>
  );
}
