import {
  Breadcrumb,
  Button,
  Input,
  Modal,
  notification,
  Pagination,
  PaginationProps,
  Popconfirm,
  Space,
  Table,
  Typography,
} from "antd";

import { Content } from "antd/es/layout/layout";
import { NotificationInstance } from "antd/es/notification/interface";
import { ColumnsType } from "antd/es/table";
import axios from "axios";
import { Key, useEffect, useLayoutEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import Header from "../../../components/common/Header";

import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";
import { categoryListData, findCategoryName } from "../../../utils/data";
import { preventSpaceBar } from "../../../utils/inputOnlyNumber";

import { navFilter, urlFilter } from "../../../utils/paramsFilter";
import { TRACKTABLE } from "../Sounds/type";
import { TableCategory, TableCategorySound } from "./type";

const { Title } = Typography;

export default function SoundTablePage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const keyword = params.get("keyword");
  const order = params.get("order");
  const sort = params.get("sort");
  const type = params.get("type");

  const [open, setOpen] = useState(false);
  const [data, setData] = useState<TableCategorySound[]>([]);
  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const TableColumn: ColumnsType<TableCategorySound> = [
    {
      title: "타이틀",
      dataIndex: "titleKo",
      render: (_, record) => record.titleKo,
      ellipsis: true,
    },
    {
      title: "카테고리",
      dataIndex: "category",
      render: (_, record) => record.categoryKo,
    },
    {
      title: "좋아요수",
      dataIndex: "likeCount",
      ellipsis: true,
    },
    {
      title: (
        <Popconfirm
          disabled={selectedRowKeys.length === 0}
          title="카테고리 사운드 삭제"
          description="카테고리 사운드를 삭제하시겠습니까?"
          onConfirm={(e: any) => {
            handleDeleteAll();
          }}
          onCancel={(e: any) => {}}
          okText="Yes"
          cancelText="No"
        >
          <Button danger disabled={selectedRowKeys.length === 0}>
            삭제
          </Button>
        </Popconfirm>
      ),
      render: (_, record) => (
        <>
          <Space>
            <Button
              size="small"
              onClick={(e) => {
                e.stopPropagation(); // Stop event propagation
                // Handle edit logic here
                handleEdit(record);
              }}
            >
              수정
            </Button>
            <Popconfirm
              title="카테고리 사운드 삭제"
              description="카테고리 사운드를 삭제하시겠습니까?"
              onConfirm={(e: any) => {
                e.stopPropagation(); // Stop event propagation
                handleDelete(record);
              }}
              onCancel={(e: any) => {
                e.stopPropagation(); // Stop event propagation
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                danger
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                삭제
              </Button>
            </Popconfirm>
          </Space>
        </>
      ),
    },
  ];

  const handleEdit = (record: TRACKTABLE) => {
    navigate(LPPUrls.Admin.Sounds.Update.url(record.id));
  };

  /** 개별 삭제 */
  const handleDelete = (record: TRACKTABLE) => {
    LPPAXIOS.post(`/admin/category/delete/track`, {
      trackIds: [record.id],
    })
      .then((resolve) => {
        api.success({
          message: "곡 삭제 성공",
        });
        setData((prev) => prev.filter((item) => item.id !== record.id));
      })
      .catch((error) => {
        if (error.response.status === 400) {
          api.error({
            message: "잘못된 요청입니다.",
          });
        }
        if (error.response.status === 403) {
          api.error({
            message: "삭제 권한이 없습니다.",
          });
        }
        if (error.respone.status === 404) {
          api.error({
            message: "요청을 찾을 수 없습니다.",
          });
        }
      });
  };
  const handleDeleteAll = () => {
    // Handle delete action here

    LPPAXIOS.post(`/admin/category/delete/track`, {
      trackIds: selectedRowKeys,
    })
      .then((resolve) => {
        api.success({
          message: "곡 삭제 성공",
        });
        window.location.reload();
      })
      .catch((error) => {
        if (error.response.status === 400) {
          api.error({
            message: "잘못된 요청입니다.",
          });
        }
        if (error.response.status === 403) {
          api.error({
            message: "삭제 권한이 없습니다.",
          });
        }
        if (error.respone.status === 404) {
          api.error({
            message: "요청을 찾을 수 없습니다.",
          });
        }
      });
  };

  const onClickModal = () => {
    setOpen((prev) => !prev);
  };

  const handleTableChange = (value: any, filter: any, sorter: any) => {
    let order =
      sorter.order === null ? null : sorter.order === "ascend" ? "ASC" : "DESC";
    let sort = sorter && sorter.field ? sorter.field : null;

    let type =
      Array.isArray(filter["type"]) && filter["type"].length
        ? filter["type"][0]
        : null;

    handleChangeSort(sort, order, type);
  };

  const handleChangeSort = (
    sort: null | string,
    order: null | string,
    type: null | string
  ) => {
    navigate(
      LPPUrls.Admin.Category.Main.url(
        currentPage,
        navFilter({ keyword: keyword, sort: sort, order: order, type: type })
      )
    );
    setCurrentPage(currentPage);
  };

  //   const handleOnSearchFilter = (value: string) => {
  //     navigate(
  //       LPPUrls.Admin.Category.Main.url(
  //         1,
  //         navFilter({ keyword: value, sort: sort, order: order, type: type })
  //       )
  //     );
  //     setCurrentPage(1);
  //   };

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      LPPUrls.Admin.Category.Main.url(
        page,
        navFilter({ keyword: keyword, sort: sort, order: order, type: type })
      )
    );
    setCurrentPage(page);
  };

  useLayoutEffect(() => {
    setLoading(true);
    LPPAXIOS.get(
      urlFilter({
        url: `/admin/track/search`,
        page: currentPage,
        perPage: 25,
        keyword: keyword,
        sort: sort,
        order: order,
        type: type,
      }) + `&categoryId=${id}`
    )
      .then((res) => {
        setData(res.data.list);
        setTotal(res.data.total);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: `Notification ${error.code}`,
            description: `${error.message}`,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [id, api, currentPage, order, sort, type, keyword]);
  return (
    <>
      {contextHolder}
      <Header className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm">
        <Title level={4}>{findCategoryName(id!)} 카테고리 사운드 조회</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Category.Main.url()}>카테고리 조회</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {findCategoryName(id!)} 카테고리 사운드 조회
          </Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Content className="m-2 bg-white">
        <Table
          rowClassName={() => "cursor-pointer"}
          loading={loading}
          pagination={false}
          rowKey={(render) => render.id}
          dataSource={data}
          columns={TableColumn}
          onChange={(val: any, filter: any, sorter: any, extra: any) => {
            handleTableChange(val, filter, sorter);
          }}
          onRow={(record, _) => {
            return {
              onClick: () => {
                // navigate(LPPUrls.Admin.Sounds.Detail.url(record.id));
              }, // click row
            };
          }}
          rowSelection={{
            selectedRowKeys,
            onChange: (selectedKeys) => {
              setSelectedRowKeys(selectedKeys);
            },
          }}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <Space>
                <Title level={4}>
                  {findCategoryName(id!)} 카테고리 사운드 리스트
                </Title>
              </Space>
              <Button onClick={onClickModal}>사운드 추가</Button>
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
        />
        {open ? (
          <SearchTrackModal
            id={id}
            open={open}
            api={api}
            onClickModal={onClickModal}
            // setAlbumTrackList={setAlbumTrackList}
          />
        ) : null}
      </Content>
    </>
  );
}

const TableColumn: ColumnsType<TRACKTABLE> = [
  {
    title: "타이틀",
    dataIndex: "titleKo",

    ellipsis: true,
  },
  {
    title: "카테고리",
    dataIndex: "categoryKo",
  },
];

interface ISearchTrackModalProps {
  id: string | undefined;
  open: boolean;
  api: NotificationInstance;
  onClickModal: () => void;
  // setAlbumTrackList: React.Dispatch<React.SetStateAction<TRACKTABLE[]>>;
}

function SearchTrackModal(props: ISearchTrackModalProps) {
  const { id, open, api, onClickModal } = props;
  const [loading, setLoading] = useState(false);

  const [trackList, setTrackList] = useState<TRACKTABLE[]>([]);
  const [selectedTrackList, setSelectedTrackList] = useState<TRACKTABLE[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [keyword, setKeyword] = useState("");

  const onOk = () => {
    onClickModal();
    LPPAXIOS.post(`/admin/category/set/${id}`, {
      trackIds: selectedTrackList.map((item) => item.id),
    })
      .then((resolve) => {
        api.success({
          message: "앨범 곡 추가 성공",
          description: "페이지를 갱신하세요.",
        });
      })
      .catch((error) => {
        console.log(error);
        api.error({
          message: "앨범 곡 추가 실패",
          description: error.response.status,
        });
      });
  };
  const handleOnSearchFilter = (value: string) => {
    // 왼쪽 목록에서의 검색을 처리합니다.
    setKeyword(value);
    setCurrentPage(1); // 검색할 때 페이지를 다시 설정합니다.
    fetchData(1, value);
  };

  const fetchData = (page: number, keyword: string) => {
    setLoading(true);
    LPPAXIOS.get(
      urlFilter({
        url: `/admin/track/search`,
        page: page,
        perPage: 25,
        keyword: keyword,
      })
    )
      .then((response) => {
        // 여기서는 key 속성을 각 트랙 객체에 추가해야 합니다.
        const tracksWithKeys = response.data.list.map((track: TRACKTABLE) => ({
          ...track,
          key: track.id.toString(), // Transfer 컴포넌트를 위해 key 속성을 추가합니다.
        }));
        setTrackList(tracksWithKeys);
        setTotal(response.data.total);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData(currentPage, keyword);
  }, [currentPage, keyword]);

  // 여기에 페이지네이션 처리 함수를 추가할 수 있습니다.

  return (
    <Modal open={open} onCancel={onClickModal} onOk={onOk} className="w-full">
      <Space className="flex items-start w-full">
        <Table
          scroll={{ y: 400 }}
          loading={loading}
          pagination={false}
          rowKey={(render) => render.id}
          dataSource={trackList}
          columns={TableColumn}
          onRow={(record, _) => {
            return {
              onClick: () => {}, // click row
              onDoubleClick: () => {
                //곡 추가
                const select = trackList.find(
                  (track) => track.id === record.id
                );

                setSelectedTrackList((prev) => {
                  // 'select'가 존재하는지 여부를 통해 'select'가 존재하면 현재 상태를 그대로 반환
                  const isExist = prev.some((item) => item.id === select?.id);
                  if (isExist) {
                    // 이미 존재하면 아무것도 변경하지 않음
                    return prev;
                  } else {
                    // 존재하지 않으면 새로운 배열을 반환하면서 'select'를 추가
                    return [...prev, select!];
                  }
                });
              },
            };
          }}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <Title level={5}>검색결과</Title>
              <Input.Search
                defaultValue={keyword ?? ""}
                style={{ width: 300 }}
                placeholder="사운드 타이틀을 검색하세요."
                onSearch={handleOnSearchFilter}
                onInput={preventSpaceBar}
              />
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={(page) => setCurrentPage(page)}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
        />

        <Table
          scroll={{ y: 400 }}
          loading={loading}
          pagination={false}
          rowKey={(render) => render.id}
          dataSource={selectedTrackList}
          columns={TableColumn}
          onRow={(record, _) => {
            return {
              onClick: () => {
                // 곡삭제
              }, // click row
              onDoubleClick: () => {
                const selectId = record.id; // 이 부분은 당신의 코드에 따라 달라질 수 있습니다.

                setSelectedTrackList((prev) => {
                  // 'selectId'에 해당하는 아이템을 제외하고 새로운 배열을 생성
                  return prev.filter((track) => track.id !== selectId);
                });
              },
            };
          }}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <Title level={5}>추가할 사운드</Title>
            </div>
          )}
        />
      </Space>
    </Modal>
  );
}
