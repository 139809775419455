import { Button, Checkbox, Divider } from "antd";
import { Content } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import { useState } from "react";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import { LPPAXIOS } from "../framework/api/core.api";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import useNotification from "antd/es/notification/useNotification";
import { LPPUrls } from "../LPPUrls";

const plainOptions = [
  "이용약관 동의 (필수)",
  "개인정보 수집 및 이용 동의(필수)",
  "프로모션 정보 수신 동의(필수)",
];
const defaultCheckedList: string[] = [];
export default function AgreementPage() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [checkedList, setCheckedList] =
    useState<CheckboxValueType[]>(defaultCheckedList);
  const [loading, setLoading] = useState(false);

  const [api, contextHolder] = useNotification();

  const checkAll = plainOptions.length === checkedList.length;
  const indeterminate =
    checkedList.length > 0 && checkedList.length < plainOptions.length;

  const onChange = (option: CheckboxValueType) => {
    if (checkedList.includes(option)) {
      setCheckedList(checkedList.filter((item) => item !== option));
    } else {
      setCheckedList([...checkedList, option]);
    }
  };
  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? plainOptions : []);
  };

  const onClickCompleteAgreement = () => {
    console.log({
      ...state,
      agreements: [...checkedList],
    });
    setLoading(true);
    LPPAXIOS.post(`/seller/seller1`, {
      ...state,
      agreements: [...checkedList],
    })
      .then((resolve) => {
        api.success({
          message: "가입 신청이 완료되었습니다.",
          description: "승인 결과는 메일을 통해 알려드립니다.",
        });
        navigate(LPPUrls.Auth.Login.Main.pathName);
      })
      .catch((error) => {
        api.error({
          message: "가입 신청이 실패하였습니다.",
          description: "관리자에게 문의하세요.",
        });
        navigate(LPPUrls.Auth.Login.Main.pathName);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  if (state === null) {
    return <Navigate to={LPPUrls.Auth.Login.Main.pathName} replace />;
  }
  return (
    <Content className="flex flex-col items-center">
      {contextHolder}
      <Title level={3}>AZ</Title>

      <Checkbox
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
      >
        AZ 이용약관, 개인정보 수집 및 이용, 프로모션 정보 수신(선택)에 모두
        동의합니다.
      </Checkbox>

      <div className="flex flex-col gap-4 mb-4">
        {plainOptions.map((option, index) => (
          <div key={index}>
            <Checkbox
              value={option}
              checked={checkedList.includes(option)}
              onChange={() => onChange(option)} // 변경된 부분
            >
              {option}
            </Checkbox>
            <div className="w-[600px] min-h-[200px] overflow-y-scroll bg-slate-200">
              {index === 0 &&
                `개인정보보호법에 따라 JUN에 회원가입 신청하시는 분께 수집하는 개인정보의 항목, 개인정보의 수집 및
                이용목적, 개인정보의 보유 및 이용기간, 동의 거부권 및 동의 거부 시 불이익에 관한 사항을 안내 드리오니
                자세히 읽은 후 동의하여 주시기 바랍니다.1. 수집하는 개인정보 이용자는 회원가입을 하지 않아도 정보 검색,
                뉴스 보기 등 대부분의 네이버 서비스를 회원과 동일하게 이용할 수 있습니다. 이용자가 메일, 캘린더, 카페,
                블로그 등과 같이 개인화 혹은 회원제 서비스를 이용하기 위해 회원가입을 할 경우, 네이버는 서비스 이용을
                위해 필요한 최소한의 개인정보를 수집합니다.`}
              {index === 1 &&
                `JUN에서 제공하는 이벤트/혜택 등 다양한 정보를 휴대전화(JUN앱 알림 또는 문자), 이메일로 받아보실 수
               있습니다. 일부 서비스(별도 회원 체계로 운영하거나 JUN 가입 이후 추가 가입하여 이용하는 서비스 등)의
               경우, 개별 서비스에 대해 별도 수신 동의를 받을 수 있으며, 이때에도 수신 동의에 대해 별도로 안내하고
               동의를 받습니다.`}
              {index === 2 &&
                `여러분을 환영합니다. JUN 서비스 및 제품(이하 ‘서비스’)을 이용해 주셔서 감사합니다. 본 약관은 다양한 JUN
               서비스의 이용과 관련하여 JUN 서비스를 제공하는 JUN 주식회사(이하 ‘JUN’)와 이를 이용하는 JUN 서비스
               회원(이하 ‘회원’) 또는 비회원과의 관계를 설명하며, 아울러 여러분의 JUN 서비스 이용에 도움이 될 수 있는
               유익한 정보를 포함하고 있습니다. JUN 서비스를 이용하시거나 JUN 서비스 회원으로 가입하실 경우 여러분은 본
               약관 및 관련 운영 정책을 확인하거나 동의하게 되므로, 잠시 시간을 내시어 주의 깊게 살펴봐 주시기
               바랍니다.`}
            </div>
          </div>
        ))}
      </div>

      <Button
        className="w-[600px]"
        type="primary"
        disabled={!checkAll}
        onClick={onClickCompleteAgreement}
      >
        동의하고 가입완료하기
      </Button>
    </Content>
  );
}
