import {
  Button,
  Divider,
  Form,
  Input,
  Upload,
  UploadProps,
  message,
  Card,
  Breadcrumb,
} from "antd";
import Header from "../../../components/common/Header";
import useNotification from "antd/es/notification/useNotification";

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HContent } from "../../../components/common/HContent";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";

import { UploadFile } from "antd/lib/upload/interface";
import { findCategory } from "../../../utils/data";
import { InboxOutlined } from "@ant-design/icons";
import { UploadOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import { RcFile } from "antd/es/upload";
import { handleUploadCompMultiFilesV2 } from "../../../utils/uploadImgToS3";

const { Dragger } = Upload;
export default function CreatePage() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = useNotification();
  const [form] = Form.useForm();

  const [artwork, setArtwork] = useState<UploadFile[]>([]);
  const [musicFile, setMusicFile] = useState<UploadFile[]>([]);

  const handleUpdateTrack = async (formValue: any) => {
    setLoading(true);
    try {
      const artworkImage =
        (await handleUploadCompMultiFilesV2(artwork, "image", "theme")) ?? [];

      const soundSrc =
        (await handleUploadCompMultiFilesV2(musicFile, "audio", "theme")) ?? [];

      const result = {
        ...formValue,
        artwork: artworkImage[0],

        url: soundSrc[0],
        viewYn: "Y",
      };
      console.log(result);

      const response = await LPPAXIOS.post(`/admin/theme`, result);
      api.success({
        message: "배경테마 생성이 완료되었습니다.",
      });
      setTimeout(() => {
        navigate(LPPUrls.Admin.BackgroundThemeManagement.Main.url(), {
          replace: true,
        });
      }, 500);
    } catch (error) {
      api.error({
        message: "배경테마 생성이 실패하였습니다.",
      });
    } finally {
      setLoading(false);
    }
  };

  const onChangeArtWork: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    const newupdatedFileList = newFileList.map((file) => {
      if (file.status !== "done") {
        file.status = "done";
      }
      return file;
    });
    setArtwork(newupdatedFileList);
  };

  const onChangeMusicFile: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    const newupdatedFileList = newFileList.map((file) => {
      if (file.status !== "done") {
        file.status = "done";
      }
      return file;
    });
    setMusicFile(newupdatedFileList);
  };

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm">
        <Title level={4}>배경테마 생성</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.BackgroundThemeManagement.Main.url()}>
              배경테마 조회
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>배경테마 생성</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Form form={form} onFinish={handleUpdateTrack}>
          <Title level={4}>언어</Title>

          <Card title="한국어">
            <Form.Item
              name="titleKo"
              label="제목"
              rules={[
                {
                  required: true,
                  message: "제목을 입력하세요.",
                },
                { max: 20, message: "최대 20자까지 작성가능합니다." },
              ]}
            >
              <Input maxLength={20} />
            </Form.Item>
          </Card>

          <Card title="영어">
            <Form.Item
              name="titleEn"
              label="제목"
              rules={[
                {
                  required: true,
                  message: "제목을 입력하세요.",
                },
                { max: 20, message: "최대 20자까지 작성가능합니다." },
              ]}
            >
              <Input maxLength={20} />
            </Form.Item>
          </Card>

          <Divider />

          <Title level={4}>미디어 파일</Title>
          <Form.Item
            name="artwork"
            label="고화질 이미지"
            extra="고화질 이미지 등록"
            rules={[
              {
                validator(rule, value, callback) {
                  if (artwork.length > 0) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject();
                  }
                },
                message: "고화질 이미지 등록은 필수입니다.",
              },
            ]}
          >
            <Upload
              fileList={artwork}
              name="artwork"
              multiple={false}
              maxCount={1}
              listType="picture"
              className="upload-list-inline"
              showUploadList={{ showPreviewIcon: true }}
              beforeUpload={(file: RcFile) => {
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                  message.error("Image must smaller than 2MB!");
                }
                return isLt2M;
              }}
              customRequest={({ file, onSuccess }) => {
                const res = "Ok";
                onSuccess!(res);
              }}
              accept=".jpg, .jpeg, .png, .webp"
              onChange={onChangeArtWork}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            name="url"
            label="음원 파일"
            rules={[
              {
                validator(rule, value, callback) {
                  if (musicFile.length > 0) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject();
                  }
                },
                message: "음원소스 등록은 필수입니다.",
              },
            ]}
          >
            <Dragger
              fileList={musicFile}
              name="file"
              accept="audio/*"
              maxCount={1}
              multiple={false}
              onChange={onChangeMusicFile}
              beforeUpload={() => false}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                해당 영역에 클릭 또는 드래그하여 파일을 업로드합니다.
              </p>
              <p className="ant-upload-hint">
                음원을 제외한 파일업로드를 제한합니다.
              </p>
            </Dragger>
          </Form.Item>
          <Button block htmlType="submit" type="primary">
            사운드 생성
          </Button>
        </Form>
      </HContent>
    </>
  );
}
