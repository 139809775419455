import {
  Breadcrumb,
  Button,
  Divider,
  Form,
  Input,
  Select,
  Space,
  Table,
} from "antd";

import useNotification from "antd/es/notification/useNotification";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { LPPAXIOS } from "../../../../framework/api/core.api";
import { LPPUrls } from "../../../../LPPUrls";

import { HContent } from "../../../../components/common/HContent";
import Header from "../../../../components/common/Header";
import Title from "antd/es/typography/Title";
import { ColumnsType } from "antd/es/table";
import { TableCoupon } from "./type";
import { timeConverter } from "../../../../utils/dateTimeConveter";
import TypedInputNumber from "antd/es/input-number";
const TableColumn: ColumnsType<TableCoupon> = [
  {
    title: "쿠폰번호",
    dataIndex: "code",
  },
  {
    title: "쿠폰명",
    dataIndex: "name",
  },
  {
    title: "유형",
    dataIndex: "type",
  },
  {
    title: "발급날짜",
    dataIndex: "createdAt",
    render: (_, record) => timeConverter(record.createdAt),
  },
];
export default function CusCreatePage() {
  // const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [api, contextHolder] = useNotification();
  const [form] = Form.useForm();

  const [couponList, setCouponList] = useState<any[]>([]);

  const onSubmitCreate = (couponValue: any) => {
    setLoading(true);
    LPPAXIOS.post(`/admin/coupon`, { ...couponValue, code: "string" })
      .then((resolve) => {
        const response: { id: number; code: string }[] = resolve.data.ids;

        console.log(response);
        const createdAt = new Date().toISOString();
        const result = response.map((item, index) => {
          return {
            ...item,
            ...couponValue,
            createdAt: createdAt,
          };
        });
        console.log(result);
        setCouponList((prev) => [...result, ...prev]);

        api.success({
          message: "쿠폰 발급 성공",
        });
        form.resetFields();
      })
      .catch((error) => {
        console.log(error);
        api.error({
          message: "쿠폰 발급 실패.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    form.setFieldsValue({
      type: "discount",
      couponCount: 1,
      activeDuration: 1,
      value: 1,
    });
    console.log("check");
  }, [form]);

  return (
    <>
      {contextHolder}

      <Header className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm">
        <Title level={4}>B2C 쿠폰 생성</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.CouponCus.Main.url()}>B2C 쿠폰 조회</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>B2C 쿠폰 생성</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Form form={form} onFinish={onSubmitCreate}>
          <Form.Item
            label="쿠폰명"
            name="name"
            rules={[
              {
                required: true,
                message: "쿠폰명을 입력하세요.",
              },
            ]}
          >
            <Input placeholder="사운드필 7일 무료이용권" />
          </Form.Item>
          <Form.Item label="유형" name="type">
            <Select
              options={[
                { value: "subscription", label: "구독권" },
                { value: "discount", label: "할인쿠폰" },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="쿠폰 발급 매수"
            name="couponCount"
            rules={[
              {
                required: true,
                message: "발급 매수를 입력하세요.",
              },
              {
                pattern: new RegExp(/^\d+$/),
                message: "숫자만 입력 가능합니다.",
              },
              {
                type: "number",
                min: 1,
                message: "최소 발급매수는 1장 입니다.",
              },
            ]}
          >
            <TypedInputNumber placeholder="발급매수" className="w-full" />
          </Form.Item>

          <Form.Item
            label="무료 이용 기간"
            name="value"
            extra="유저가 쿠폰을 입력했을 때부터 시작됩니다."
            rules={[
              {
                required: true,
                message: "이용기간을 입력하세요.",
              },
              {
                pattern: new RegExp(/^\d+$/),
                message: "숫자만 입력 가능합니다.",
              },
              {
                type: "number",
                min: 1,
                message: "최소 이용기간은 1일 입니다.",
              },
            ]}
          >
            <TypedInputNumber
              placeholder="숫자만 입력하세요."
              className="w-full"
            />
          </Form.Item>
          <Form.Item
            label="등록/사용 가능한 유효기간(일)"
            name="activeDuration"
            extra="현재 기준일 + activeDuration"
            rules={[
              {
                required: true,
                message: "등록/사용 가능한 유효기간(일)을 입력하세요.",
              },
              {
                pattern: new RegExp(/^\d+$/),
                message: "숫자만 입력 가능합니다.",
              },
              {
                type: "number",
                min: 1,
                message: "최소 등록/사용 가능한 유효기간(일)은 1일 입니다.",
              },
            ]}
          >
            <TypedInputNumber
              placeholder="숫자만 입력하세요."
              className="w-full"
            />
          </Form.Item>

          <Space className="flex justify-end w-full">
            <Button htmlType="submit" type="primary" loading={loading}>
              쿠폰 생성
            </Button>
          </Space>
        </Form>
        <Divider />
        <Table
          rowKey={(record) => record.id}
          dataSource={couponList}
          columns={TableColumn}
          title={() => (
            <div className="flex items-center justify-end p-2">
              <Button
                onClick={() => {
                  //해당 데이터 엑셀파일로 변환.
                }}
              >
                엑셀저장
              </Button>
            </div>
          )}
        />
      </HContent>
    </>
  );
}
