import { Button, Card, Form, Input } from "antd";
import { RuleObject } from "antd/lib/form";

import {
  MailOutlined,
  PhoneOutlined,
  LinkOutlined,
  UnlockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { LPPUrls } from "../LPPUrls";

import { onPressOnlyNumber } from "../utils/inputOnlyNumber";
import TextArea from "antd/es/input/TextArea";
import Password from "antd/es/input/Password";
import { LPPAXIOS } from "../framework/api/core.api";
import useNotification from "antd/es/notification/useNotification";
import { useState } from "react";
import { LoadingSpin } from "../components/common/Loading";

interface FormValues {
  nickName: string;
  platform: string;
  appNickName: string;
  phone: string;
  email: string;
  password: string;
  confirm_password: string;
  recommendedId?: string;
  memo?: string;
}

export const FindPasswordPage = () => {
  const navigate = useNavigate();
  const [api, contextHolder] = useNotification();
  const [loading, setLoading] = useState(false);
  const onFinish = (value: FormValues) => {
    setLoading(false);
    const info = {
      nickName: value.nickName,
      appNickName: value.appNickName,
      platform: value.platform,
      phone: value.phone,
      email: value.email,
      password: value.password,
      recommendedId: Number(value.recommendedId ?? 0),
      memo: value.memo ?? "",
    };
    LPPAXIOS.post(`/seller/seller`, info)
      .then((resolve) => {
        api.success({
          message: "셀러가입 신청이 완료되었습니다.",
        });
        setTimeout(() => {
          navigate(LPPUrls.Auth.Login.Main.pathName);
        }, 600);
      })
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 400) {
          api.error({
            message: "이미 가입 신청된 계정입니다.",
          });
        } else {
          api.error({
            message: "가입 실패",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const validatePasswords: RuleObject = {
    validator: async (_: RuleObject, value: string | number | undefined) => {
      if (!value || value === form.getFieldValue("password")) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("비밀번호가 일치하지 않습니다."));
    },
  };

  const [form] = Form.useForm<FormValues>();
  return (
    <div className="flex items-center justify-center w-full h-screen overflow-auto bg-slate-600">
      <LoadingSpin loading={loading} />
      {contextHolder}
      <Card title={"AZ Seller 가입 신청서"}>
        <Form
          layout="vertical"
          name="normal_login"
          className="w-[450px]"
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            label={"Seller 닉네임"}
            name="nickName"
            rules={[
              {
                required: true,
                message: "셀러 페이지에서 사용할 닉네임 입력은 필수입니다.",
              },
            ]}
          >
            <Input
              className="rounded-none"
              prefix={<UserOutlined />}
              placeholder="셀러 페이지에서 사용할 닉네임"
              // onChange={handleChange1}
            />
          </Form.Item>
          <Form.Item
            label={"AZ APP 닉네임"}
            name="appNickName"
            rules={[
              {
                required: true,
                message: "셀러 페이지에서 사용할 닉네임 입력은 필수입니다.",
              },
            ]}
          >
            <Input
              className="rounded-none"
              prefix={<UserOutlined />}
              placeholder="앱에서 사용하는 본인의 닉네임"
              // onChange={handleChange1}
            />
          </Form.Item>

          <Form.Item
            label={"이메일"}
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input
              type="email"
              className="rounded-none"
              prefix={<MailOutlined />}
              placeholder="az.hellow@aizi.com"
              // onChange={handleChange1}
            />
          </Form.Item>
          <Form.Item
            label={"비밀번호"}
            name="password"
            rules={[
              {
                required: true,
                message: "비밀번호 입력은 필수입니다.",
              },
              {
                pattern: new RegExp(
                  "^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[@$!%*?&])[A-Za-z0-9@$!%*?&]{8,30}$"
                ),
                message:
                  "비밀번호는 길이 8~30, 알파벳 대/소문자, 숫자, 특수문자 중 하나 이상을 포함해야 합니다.",
              },
            ]}
          >
            <Password
              className="rounded-none"
              prefix={<UnlockOutlined />}
              placeholder="비밀번호를 입력해주세요"
            />
          </Form.Item>

          <Form.Item
            label="비밀번호 확인"
            name="confirm_password"
            rules={[
              {
                required: true,
                message: "비밀번호 입력은 8자리 이상이 필수입니다.",
              },
              validatePasswords,
            ]}
          >
            <Input
              type="password"
              className="rounded-none"
              prefix={<UnlockOutlined />}
              placeholder="비밀번호를 입력해주세요 (8자리 이상)"
            />
          </Form.Item>
          <Form.Item
            label="운영중인 홈페이지"
            name="platform"
            rules={[
              {
                required: true,
                message:
                  "현재 운영중인 쇼핑몰 또는 플랫폼 주소를 입력해주세요.",
              },
            ]}
          >
            <Input
              className="rounded-none"
              prefix={<LinkOutlined />}
              placeholder="플랫폼 주소 or 운영중인 쇼핑몰 주소"
            />
          </Form.Item>
          <Form.Item
            label={"핸드폰"}
            name="phone"
            rules={[
              {
                required: true,
                message: "핸드폰 정보 입력은 필수입니다.",
              },
            ]}
          >
            <Input
              className="rounded-none"
              maxLength={11}
              prefix={<PhoneOutlined />}
              placeholder="01012345678 (숫자만 입력)"
              onChange={onPressOnlyNumber}
            />
          </Form.Item>

          <Form.Item label="추천인" name="recommendedId">
            <Input className="rounded-none" placeholder="추천인 아이디" />
          </Form.Item>
          <Form.Item label="참고사항" name="memo">
            <TextArea
              maxLength={500}
              className="rounded-none"
              placeholder="추가적으로 전달하실 사항이 있다면 작성해주세요."
            />
          </Form.Item>
          <Form.Item>
            <Link
              className="float-right text-blue-400"
              to={LPPUrls.Auth.Login.Main.url}
            >
              로그인 페이지
            </Link>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              className="rounded-none"
              loading={loading}
            >
              가입 신청
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};
