import { Navigate, Route, Routes } from "react-router-dom";
import { LPPUrls } from "../../../LPPUrls";
import CreatePage from "./Create";
import DetailPage from "./Detail";
import TablePage from "./Table";
import UpdatePage from "./Update";

export default function CollabotatorRoot() {
  return (
    <Routes>
      <Route path="/" element={<TablePage />} />

      <Route
        path={LPPUrls.Admin.Collabotator.Detail.pathName}
        element={<DetailPage />}
      />
      <Route
        path={LPPUrls.Admin.Collabotator.Create.pathName}
        element={<CreatePage />}
      />
      <Route
        path={LPPUrls.Admin.Collabotator.Update.pathName}
        element={<UpdatePage />}
      />

      <Route path="/*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
}
