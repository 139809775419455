export enum LoginType {
  GOOGLE = "google",
  FACEBOOK = "facebook",
  APPLE = "apple",
  NAVER = "naver",
  KAKAO = "kakao",
  EMAIL = "email",
}

export interface TABLERESPONSE {
  list: USERTABLE[];
  total: number;
}

export interface USERTABLE {
  id: number;
  nickName: string;
  email: string;
  createdAt: string;
  currentSubscription: boolean;
  type: string;
}

export interface USERDETAILTYPE {
  id: number;
  nickName: string;
  email: string;
  createdAt: string;
  userStatus: string;
  list: Subscription[];
  total: number;
}

export interface Subscription {
  id: number;
  startDate: string;
  endDate: string;
  status: string;
}
