import {
  Button,
  notification,
  Pagination,
  PaginationProps,
  Table,
  Tag,
  Typography,
} from "antd";

import { Content } from "antd/es/layout/layout";
import { ColumnsType } from "antd/es/table";
import axios from "axios";
import { useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../../../components/common/Header";
import { LPPAXIOS } from "../../../../framework/api/core.api";
import { LPPUrls } from "../../../../LPPUrls";
import { timeConverter } from "../../../../utils/dateTimeConveter";
import {
  filterNonEmptyProperties,
  paramsFilter,
} from "../../../../utils/paramsFilter";
import { COUPONSTATUS, COUPONTYPE, TableCoupon } from "./type";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";

const { Title } = Typography;

export default function TablePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const keyword = params.get("keyword");
  const order = params.get("order");
  const sort = params.get("sort");
  const type = params.get("type");
  const status = params.get("status");

  const [data, setData] = useState<TableCoupon[]>([]);
  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const TableColumn: ColumnsType<TableCoupon> = [
    {
      title: "쿠폰번호",
      dataIndex: "code",
    },
    {
      title: "쿠폰명",
      dataIndex: "name",
      ellipsis: true,
    },
    {
      title: "사용기간",
      dataIndex: "value",
      sorter: true,
      width: "10%",
      defaultSortOrder:
        sort === "value" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "유형",
      dataIndex: "type",
      render: (_, record) => {
        if (record.type === COUPONTYPE.SUBSCRIPTION) {
          return <Tag color="green">구독권</Tag>;
        }
        if (record.type === COUPONTYPE.DISCOUNT) {
          return <Tag color="blue">할인쿠폰</Tag>;
        }
      },
      filters: [
        {
          text: <Tag color="green">구독권</Tag>,
          value: COUPONTYPE.SUBSCRIPTION,
        },
        {
          text: <Tag color="blue">할인쿠폰</Tag>,
          value: COUPONTYPE.DISCOUNT,
        },
      ],
      filterMultiple: false,
      defaultFilteredValue: type === null ? [] : [type],
    },

    {
      title: "상태",
      dataIndex: "status",
      render: (_, record) => {
        if (record.status === COUPONSTATUS.USED) {
          return <Tag color="green">사용완료</Tag>;
        }
        if (record.status === COUPONSTATUS.UNASSIGNED) {
          return <Tag color="geekblue">미등록</Tag>;
        }
        if (record.status === COUPONSTATUS.REGISTERED) {
          return <Tag color="gold">등록완료</Tag>;
        }
        if (record.status === COUPONSTATUS.EXPIRED) {
          return <Tag color="cyan">기간만료</Tag>;
        }
        if (record.status === COUPONSTATUS.CANCELLED) {
          return <Tag color="red">사용취소</Tag>;
        }
      },
      filters: [
        {
          text: <Tag color="green">사용완료</Tag>,
          value: COUPONSTATUS.USED,
        },
        {
          text: <Tag color="geekblue">미등록</Tag>,
          value: COUPONSTATUS.UNASSIGNED,
        },
        {
          text: <Tag color="gold">등록완료</Tag>,
          value: COUPONSTATUS.REGISTERED,
        },
        {
          text: <Tag color="cyan">기간만료</Tag>,
          value: COUPONSTATUS.EXPIRED,
        },
        {
          text: <Tag color="red">사용취소</Tag>,
          value: COUPONSTATUS.CANCELLED,
        },
      ],
      filterMultiple: false,
      defaultFilteredValue: status === null ? [] : [status],
    },
    {
      title: "발급일",
      dataIndex: "createdAt",
      render: (_, record) => timeConverter(record.createdAt),

      sorter: true,
      defaultSortOrder:
        sort === "createdAt" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "등록일",
      dataIndex: "registeredAt",
      render: (_, record) =>
        record.registeredAt ? timeConverter(record.registeredAt) : "미등록",
    },
    {
      title: "사용일",
      dataIndex: "usedAt",
      render: (_, record) =>
        record.usedAt ? timeConverter(record.usedAt) : "미사용",
    },
  ];
  const handleExcel = async () => {
    const workbook = new Workbook();
    const couponSheet = workbook.addWorksheet("B2B 쿠폰 리스트 조회");

    couponSheet.columns = [
      { header: "Id", key: "id", width: 10 },
      { header: "쿠폰번호", key: "code", width: 32 },
      { header: "쿠폰명", key: "name", width: 20 },
      { header: "사용기간", key: "value", width: 20 },
      { header: "유형", key: "type", width: 32 },
      { header: "상태", key: "status", width: 32 },
      { header: "발급일", key: "createdAt", width: 32 },
      { header: "등록일", key: "registeredAt", width: 32 },
      { header: "사용일", key: "usedAt", width: 32 },
    ];
    data.forEach((item, idx) => {
      couponSheet.addRow({
        id: item.id,
        code: item.code,
        name: item.name,
        value: item.value,
        type: item.type,
        status: item.status,
        createdAt: timeConverter(item.createdAt),
        registeredAt: item.registeredAt
          ? timeConverter(item.registeredAt)
          : "미등록",
        usedAt: item.usedAt ? timeConverter(item.usedAt) : "미사용",
      });
    });

    // 다운로드
    const mimeType = {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    };
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], mimeType);
    saveAs(blob, `B2B 쿠폰 리스트 ${currentPage}페이지.xlsx`);
  };

  const handleTableChange = (value: any, filter: any, sorter: any) => {
    let order =
      sorter.order === null ? null : sorter.order === "ascend" ? "ASC" : "DESC";
    let sort = sorter.field ?? null;
    let result = filterNonEmptyProperties(filter);
    handleChangeSort(sort, order, result);
  };

  const handleChangeSort = (
    sort: null | string,
    order: null | string,
    result: { [key: string]: string | null }
  ) => {
    navigate(
      LPPUrls.Admin.CouponBis.Main.url(
        currentPage,
        paramsFilter({
          keyword: keyword,
          sort: sort,
          order: order,
          isEvent: 1,
          ...result,
        })
      )
    );
    setCurrentPage(currentPage);
  };

  // const handleOnSearchFilter = (value: string) => {
  //   navigate(
  //     LPPUrls.Admin.CouponBis.Main.url(
  //       1,
  //       paramsFilter({
  //         keyword: value,
  //         sort: sort,
  //         order: order,
  //         type: type,
  //         status: status,
  //         isEvent: 1,
  //       })
  //     )
  //   );
  //   setCurrentPage(1);
  // };
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      LPPUrls.Admin.CouponBis.Main.url(
        page,
        paramsFilter({
          keyword: keyword,
          sort: sort,
          order: order,
          status: status,
          type: type,
          isEvent: 1,
        })
      )
    );
    setCurrentPage(page);
  };

  const onClickCreate = () => {
    navigate(LPPUrls.Admin.CouponBis.Create.pathName);
  };

  useLayoutEffect(() => {
    setLoading(true);
    LPPAXIOS.get(
      `/admin/coupon/list?` +
        paramsFilter({
          page: currentPage,
          perPage: 25,
          keyword: keyword,
          sort: sort,
          order: order,
          status: status,
          type: type,
          isEvent: 1,
        })
    )
      .then((res) => {
        setData(res.data.list);
        setTotal(res.data.total);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: `Notification ${error.code}`,
            description: `${error.message}`,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [api, currentPage, order, sort, type, status, keyword]);
  return (
    <>
      {contextHolder}
      <Header className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm">
        <Title level={4}>B2B 쿠폰 관리</Title>
      </Header>
      <Content className="m-2 bg-white">
        <Table
          // rowClassName={() => "cursor-pointer"}
          loading={loading}
          pagination={false}
          rowKey={(render) => render.id}
          dataSource={data}
          columns={TableColumn}
          onChange={(val: any, filter: any, sorter: any, extra: any) => {
            handleTableChange(val, filter, sorter);
          }}
          title={() => (
            <div className="flex items-center justify-start gap-2 p-2">
              <Button onClick={handleExcel}>엑셀 다운로드</Button>
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
        />
      </Content>
    </>
  );
}
