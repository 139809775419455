import { Navigate, Route, Routes } from "react-router-dom";
import { LPPUrls } from "../../../../LPPUrls";

import CusDetailPage from "./CusDetail";
import CusTablePage from "./CusTable";

export default function SettlementPaymentCusRoot() {
  return (
    <Routes>
      <Route path={"/"} element={<CusTablePage />} />

      <Route
        path={LPPUrls.Admin.SettlementPaymentCus.Detail.pathName}
        element={<CusDetailPage />}
      />

      <Route path="/*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
}
