import { useQuery } from "@tanstack/react-query";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { LPPAXIOS } from "../../framework/api/core.api";
import { LPPUrls } from "../../LPPUrls";
import { soundPillAdmin, getItem } from "../../utils/localStorage";
import { LPPAdminCommonLayout } from "../Layout/LPPAdminCommonLayout";

import AlbumsRoot from "../../pages/AdminPages/Albums/Root";
import BackgroundThemeManagementRoot from "../../pages/AdminPages/BackgroundThemeManagement/Root";
import CategoryRoot from "../../pages/AdminPages/Category/Root";

import DashBoardRoot from "../../pages/AdminPages/DashBoard/Root";
import InquiryManagementRoot from "../../pages/AdminPages/InquiryManagement/Root";
import MessageRoot from "../../pages/AdminPages/Message/Root";
import ParticipantManagementRoot from "../../pages/AdminPages/ParticipantManagement/Root";
import SoundRoot from "../../pages/AdminPages/Sounds/Root";
import UsersRoot from "../../pages/AdminPages/Users/Root";
import NoticeRoot from "../../pages/AdminPages/Notice/Root";
import CouponCusRoot from "../../pages/AdminPages/Coupon/Cus/CusRoot";
import CouponBisRoot from "../../pages/AdminPages/Coupon/Bis/BisRoot";
import SettlementPaymentCusRoot from "../../pages/AdminPages/SettlementPayment/Cus/CusRoot";
import CollabotatorRoot from "../../pages/AdminPages/Collaborator/Root";

import ProductCusRoot from "../../pages/AdminPages/Product/Cus/CusRoot";
import SettlementPaymentBisRoot from "../../pages/AdminPages/SettlementPayment/Bis/BisRoot";
import ProductBisRoot from "../../pages/AdminPages/Product/Bis/BisRoot";

interface LoginSessionType {
  id: number;
  userId: number;
  role: string;
  nickName: string;
  phone: string;
  email: string;
  password: string;
  salt: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
  isDeleted: number;
  recommendedId: any;
  platform: string;
  memo: string;
  type: string;
}

export const LPPAdminRoutes = () => {
  const navigate = useNavigate();
  useQuery({
    queryKey: ["useSession"],
    queryFn: async () => {
      try {
        const res = await LPPAXIOS.get<LoginSessionType>("/admin/auth");
        if (res.status === 200) {
          localStorage.setItem(soundPillAdmin, JSON.stringify(res.data));
          return res.data;
        }
        return null;
      } catch (error) {
        localStorage.removeItem(soundPillAdmin);
        return navigate(LPPUrls.Auth.Root.pathName, { replace: true });
      }
    },
    refetchOnWindowFocus: false,
    refetchInterval: 6600 * 1000,
  });
  let localData = getItem(soundPillAdmin);

  if (localData === null) {
    return <Navigate to={LPPUrls.Auth.Root.pathName} replace />;
  }
  // const data: ResponsePostAuth = JSON.parse(localData);

  return (
    <LPPAdminCommonLayout>
      <Routes>
        {/* 대시보드 */}
        <Route
          path={LPPUrls.Admin.DashBoard.Main.pathName}
          element={<DashBoardRoot />}
        />

        {/* 사용자 관리 */}
        <Route
          path={LPPUrls.Admin.Users.Main.pathName}
          element={<UsersRoot />}
        />
        {/* 사운드 */}
        <Route
          path={LPPUrls.Admin.Sounds.Main.pathName}
          element={<SoundRoot />}
        />

        {/* 카테고리 */}
        <Route
          path={LPPUrls.Admin.Category.Main.pathName}
          element={<CategoryRoot />}
        />

        {/* 앨범 */}
        <Route
          path={LPPUrls.Admin.Albums.Main.pathName}
          element={<AlbumsRoot />}
        />

        {/* 문의 관리 */}
        <Route
          path={LPPUrls.Admin.InquiryManagement.Main.pathName}
          element={<InquiryManagementRoot />}
        />

        {/* 메세지 보내기 */}
        <Route
          path={LPPUrls.Admin.Message.Main.pathName}
          element={<MessageRoot />}
        />

        {/* 쿠폰 B2B*/}
        <Route
          path={LPPUrls.Admin.CouponBis.Main.pathName}
          element={<CouponBisRoot />}
        />
        {/* 쿠폰 B2C*/}
        <Route
          path={LPPUrls.Admin.CouponCus.Main.pathName}
          element={<CouponCusRoot />}
        />

        {/* B2B정산/결제 */}
        <Route
          path={LPPUrls.Admin.SettlementPaymentBis.Main.pathName}
          element={<SettlementPaymentBisRoot />}
        />
        {/* B2C정산/결제 */}
        <Route
          path={LPPUrls.Admin.SettlementPaymentCus.Main.pathName}
          element={<SettlementPaymentCusRoot />}
        />

        {/* 상품 B2B*/}
        <Route
          path={LPPUrls.Admin.ProductBis.Main.pathName}
          element={<ProductBisRoot />}
        />
        {/* 상품 B2C*/}
        <Route
          path={LPPUrls.Admin.ProductCus.Main.pathName}
          element={<ProductCusRoot />}
        />

        {/* 배경테마 관리 */}
        <Route
          path={LPPUrls.Admin.BackgroundThemeManagement.Main.pathName}
          element={<BackgroundThemeManagementRoot />}
        />

        {/* 참여진 관리 */}
        <Route
          path={LPPUrls.Admin.ParticipantManagement.Main.pathName}
          element={<ParticipantManagementRoot />}
        />
        {/* 공지사항 관리 */}
        <Route
          path={LPPUrls.Admin.Notice.Main.pathName}
          element={<NoticeRoot />}
        />
        {/* 협업 회사 관리 */}
        <Route
          path={LPPUrls.Admin.Collabotator.Main.pathName}
          element={<CollabotatorRoot />}
        />

        <Route
          path={"*"}
          element={<Navigate to={LPPUrls.Admin.Users.Main.url()} />}
        />
      </Routes>
    </LPPAdminCommonLayout>
  );
};
