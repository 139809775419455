import { Navigate, Route, Routes } from "react-router-dom";
import { LPPUrls } from "../../LPPUrls";
import { LPPAdminRoutes } from "./LPPAdminRoutes";
import { LPPAuthRoutes } from "./LPPAuthRoutes";

export const LPPRootRoutes = () => {
  return (
    <Routes>
      <Route path={LPPUrls.Auth.Root.pathName} element={<LPPAuthRoutes />} />
      <Route path={LPPUrls.Admin.Root.pathName} element={<LPPAdminRoutes />} />
      <Route
        path={"*"}
        element={<Navigate to={LPPUrls.Auth.Root.pathName} />}
      />
    </Routes>
  );
};
