import axios from "axios";
import { LPPUrls } from "../../LPPUrls";

export interface RequestPostAuth {
  name: string;
  password: string;
}
export interface ResponsePostAuth {
  id: number;
  name: string;
  role: "admin" | "staff";
  createdAt: string;
}

export const LPPAXIOS = axios.create({
  baseURL: `${process.env.REACT_APP_ENDPOINT}`,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

// 쿠키를 삭제하는 함수
function deleteAllCookies() {
  document.cookie.split(";").forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
}

// Response Interceptor
LPPAXIOS.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      deleteAllCookies(); // 모든 쿠키 삭제

      localStorage.clear(); // 로컬스토리지 삭제

      window.location.href = "/auth/adminLogin";
    }
    return Promise.reject(error);
  }
);
// LPPAXIOS.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     if (error.response.message === "invalid_token") {
//       localStorage.clear();
//       window.location.reload();
//     } else {
//       throw error;
//     }
//   }
// );

export const IMGUPLOAD = axios.create({
  headers: {
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
  },
});
