import {
  Button,
  Descriptions,
  Divider,
  Result,
  Space,
  Table,
  Typography,
} from "antd";
import Breadcrumb from "antd/es/breadcrumb/Breadcrumb";
import useNotification from "antd/es/notification/useNotification";
import { ColumnsType } from "antd/es/table";
import Title from "antd/es/typography/Title";
import axios, { AxiosError } from "axios";
import { useEffect, useLayoutEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HContent } from "../../../components/common/HContent";
import Header from "../../../components/common/Header";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";
import { timeConverter } from "../../../utils/dateTimeConveter";
import { InquiryDetail } from "./type";

export default function DetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any | AxiosError | null>(null);
  const [api, contextHolder] = useNotification();

  const [data, setData] = useState<InquiryDetail>({
    id: 1,
    userId: 76,
    email: "adf@aadf.com",
    type: "kakao",
    title: "제목ㅋ",
    content: "내용ㅋ",
    answer: "답변이어라",
    createdAt: "2023-10-24T06:53:28.000Z",
    updatedAt: "2023-11-09T01:31:32.000Z",
    deletedAt: null,
    dlYn: "N",
    viewYn: "Y",
  });

  const onClickBack = () => {
    navigate(LPPUrls.Admin.InquiryManagement.Main.url());
  };

  const onClickUpdate = () => {
    navigate(LPPUrls.Admin.InquiryManagement.Update.url(id!));
  };

  useLayoutEffect(() => {
    setLoading(true);
    LPPAXIOS.get(`/admin/inquiry/${id}`)
      .then((resolve) => {
        setData(resolve.data);
      })
      .catch((error) => {
        // if (axios.isAxiosError(error)) {
        //   return setError(error);
        // }
        return setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);
  if (error !== null && error.response.status === 404)
    return (
      <Result
        status={404}
        title={"404"}
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );
  if (error !== null && error.response.status === 500)
    return (
      <Result
        status={500}
        title={"500"}
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );
  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm">
        <Title level={4}>1:1 문의 상세</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.InquiryManagement.Main.url()}>
              1:1문의 조회
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>1:1 문의 상세</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Typography>
          <Typography>작성자:{data.userId} {data.email} {data.type}</Typography>
          <Typography>작성일자:{timeConverter(data.createdAt)}</Typography>
          <Title level={4}>{data.title}</Title>
          <Divider />
          <Typography>{data.content}</Typography>
          <Divider>답변</Divider>
          {data.answer === null ? (
            <>
              <Space className="w-full rounded-lg bg-slate-100 min-h-[200px] flex justify-center  p-2">
                <Typography.Title level={2} className="text-slate-400">
                  아직 답변이 없습니다.
                </Typography.Title>
              </Space>
              <Button block className="mt-2" onClick={onClickUpdate}>
                답변 달기
              </Button>
            </>
          ) : (
            <>
              <Space className="w-full rounded-lg bg-slate-100 min-h-[200px] flex items-start p-2">
                <Typography>{data.answer}</Typography>
              </Space>
              <Button block className="mt-2" onClick={onClickUpdate}>
                답변 수정
              </Button>
            </>
          )}
        </Typography>
      </HContent>
    </>
  );
}
