import { Navigate, Route, Routes } from "react-router-dom";
import { LPPUrls } from "../../../../LPPUrls";
import CusCreatePage from "./CusCreate";
import CusDetailPage from "./CusDetail";
import CusTablePage from "./CusTable";
import CusUpdatePage from "./CusUpdate";

export default function ProductCusRoot() {
  return (
    <Routes>
      <Route path={"/"} element={<CusTablePage />} />
      <Route
        path={LPPUrls.Admin.ProductCus.Detail.pathName}
        element={<CusDetailPage />}
      />
      <Route
        path={LPPUrls.Admin.ProductCus.Create.pathName}
        element={<CusCreatePage />}
      />
      <Route
        path={LPPUrls.Admin.ProductCus.Update.pathName}
        element={<CusUpdatePage />}
      />

      <Route path="/*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
}
