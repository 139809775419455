import { Navigate, Route, Routes } from "react-router-dom";
import { LPPUrls } from "../../../../LPPUrls";
import CusCreatePage from "./CusCreate";
// import CusDetailPage from "./CusDetail";
import CusTablePage from "./CusTable";

export default function CouponCusRoot() {
  return (
    <Routes>
      <Route path={"/"} element={<CusTablePage />} />

      {/* <Route
        path={LPPUrls.Admin.CouponCus.Detail.pathName}
        element={<CusDetailPage />}
      /> */}

      <Route
        path={LPPUrls.Admin.CouponCus.Create.pathName}
        element={<CusCreatePage />}
      />

      <Route path="/*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
}
