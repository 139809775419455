import { Button, Layout, Menu, Space, theme, Typography } from "antd";
import type { MenuProps } from "antd";
import { useEffect, useState } from "react";

import { LPPUrls } from "../../LPPUrls";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Header } from "antd/es/layout/layout";
import { soundPillAdmin, getItem as localItem } from "../../utils/localStorage";
import { LPPAXIOS } from "../../framework/api/core.api";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
  theme?: "light" | "dark"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    theme,
  } as MenuItem;
}

const { Sider } = Layout;

interface ILPPAdminCommonLayoutProps {
  children: React.ReactNode;
  role?: "seller" | "admin";
}

export const LPPAdminCommonLayout = (props: ILPPAdminCommonLayoutProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { nickName } = localItem(soundPillAdmin);
  const { children } = props;
  const [current, setCurrent] = useState("");
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const items: MenuProps["items"] = [
    // getItem(
    //   <Link to={LPPUrls.Admin.DashBoard.Main.url()}>대시보드</Link>,
    //   LPPUrls.Admin.DashBoard.Main.key
    // ),
    getItem(
      <Link to={LPPUrls.Admin.Users.Main.url()}>사용자</Link>,
      LPPUrls.Admin.Users.Main.key
    ),

    getItem(
      <Link to={LPPUrls.Admin.Sounds.Main.url()}>사운드</Link>,
      LPPUrls.Admin.Sounds.Main.key
    ),

    getItem(
      <Link to={LPPUrls.Admin.Category.Main.url()}>카테고리</Link>,
      LPPUrls.Admin.Category.Main.key
    ),

    getItem(
      <Link to={LPPUrls.Admin.Albums.Main.url()}>앨범</Link>,
      LPPUrls.Admin.Albums.Main.key
    ),

    getItem(
      <Link to={LPPUrls.Admin.InquiryManagement.Main.url()}>1:1 문의</Link>,
      LPPUrls.Admin.InquiryManagement.Main.key
    ),

    // getItem(
    //   <Link to={LPPUrls.Admin.Message.Main.url()}>메세지 보내기</Link>,
    //   LPPUrls.Admin.Message.Main.key
    // ),

    getItem("쿠폰/선물", "CouponMain", null, [
      getItem(
        <Link to={LPPUrls.Admin.CouponBis.Main.url()}>B2B</Link>,
        LPPUrls.Admin.CouponBis.Main.key
      ),
      getItem(
        <Link to={LPPUrls.Admin.CouponCus.Main.url()}>B2C</Link>,
        LPPUrls.Admin.CouponCus.Main.key
      ),
    ]),

    getItem("정산/결제", "SettlementPaymentMain", null, [
      getItem(
        <Link to={LPPUrls.Admin.SettlementPaymentBis.Main.url()}>B2B</Link>,
        LPPUrls.Admin.SettlementPaymentBis.Main.key
      ),
      getItem(
        <Link to={LPPUrls.Admin.SettlementPaymentCus.Main.url()}>B2C</Link>,
        LPPUrls.Admin.SettlementPaymentCus.Main.key
      ),
    ]),

    getItem("상품", "ProductMain", null, [
      getItem(
        <Link to={LPPUrls.Admin.ProductBis.Main.url()}>B2B</Link>,
        LPPUrls.Admin.ProductBis.Main.key
      ),
      getItem(
        <Link to={LPPUrls.Admin.ProductCus.Main.url()}>B2C</Link>,
        LPPUrls.Admin.ProductCus.Main.key
      ),
    ]),

    getItem(
      <Link to={LPPUrls.Admin.Notice.Main.url()}>공지사항</Link>,
      LPPUrls.Admin.Notice.Main.key
    ),

    getItem(
      <Link to={LPPUrls.Admin.BackgroundThemeManagement.Main.url()}>
        배경테마
      </Link>,
      LPPUrls.Admin.BackgroundThemeManagement.Main.key
    ),
    getItem(
      <Link to={LPPUrls.Admin.ParticipantManagement.Main.url()}>참여진</Link>,
      LPPUrls.Admin.ParticipantManagement.Main.key
    ),
    getItem(
      <Link to={LPPUrls.Admin.Collabotator.Main.url()}>협업 회사 관리</Link>,
      LPPUrls.Admin.Collabotator.Main.key
    ),
  ];

  const handleLogout = async () => {
    try {
      const res = await LPPAXIOS.delete("/admin/auth");
      if (res.status === 204) {
        localStorage.removeItem(soundPillAdmin);
        return navigate(LPPUrls.Auth.Root.pathName, { replace: true });
      }
    } catch (error) {}
  };

  useEffect(() => {
    setCurrent(location.pathname.split("/").slice(2, 4).join(""));
  }, [location.pathname]);

  return (
    <Layout>
      <Layout>
        <Sider
          className="scrollbar-hide"
          style={{
            // background: "#4D9226",
            overflowY: "auto",
          }}
        >
          {process.env.REACT_APP_CHECK_ENV === "_dev_" && (
            <Typography.Title level={1} style={{ color: "white" }}>
              ..Develop..
            </Typography.Title>
          )}

          <div className="w-full h-[60px] p-2">
            <div className="w-full h-full text-xl text-center text-white rounded-lg">
              SoundPill
            </div>
          </div>

          <Menu
            items={items}
            mode="inline"
            selectedKeys={[current]}
            // theme={collapsed ? "dark" : "light"}
          />
        </Sider>
        <Layout className="h-screen overflow-auto bg-gray-50">
          <Header
            style={{
              background: colorBgContainer,
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Space>
              <Typography.Text>{nickName}</Typography.Text>
              <Button onClick={handleLogout}>Logout</Button>
            </Space>
          </Header>
          {children}
        </Layout>
      </Layout>
    </Layout>
  );
};
