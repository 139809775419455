export function onInputOnlyNumber(e: any) {
  if (e.currentTarget.value.match(/[^0-9]/g)) {
    e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, "");
  }
  if (e.currentTarget.value.length > e.currentTarget.maxLength)
    e.currentTarget.value = e.currentTarget.value.slice(
      0,
      e.currentTarget.maxLength
    );
}
export function onPressOnlyNumber(event: any) {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
}
export function preventSpaceBar(e: any) {
  e.currentTarget.value = e.currentTarget.value.replace(/^\s*/, "");
  if (e.currentTarget.value.trim() === "") {
    e.currentTarget.value = "";
  }
}
