import { Navigate, Route, Routes } from "react-router-dom";
import MainPage from "./Main";

export default function DashBoardRoot() {
  return (
    <Routes>
      <Route path={"/"} element={<MainPage />} />
      <Route path="/*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
}
