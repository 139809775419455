export const formatPhoneNumber = (value: string): string => {
  console.log("formatPhoneNumber params::", value);
  const numbers = value.replace(/[^\d]/g, "");
  console.log("formatPhoneNumber number::", numbers);

  // `010`으로 시작하는 경우
  if (numbers.startsWith("010")) {
    if (numbers.length <= 3) {
      return numbers;
    }
    if (numbers.length <= 7) {
      return `${numbers.slice(0, 3)}-${numbers.slice(3)}`;
    }
    return `${numbers.slice(0, 3)}-${numbers.slice(3, 7)}-${numbers.slice(7)}`;
  }

  // 그 외 지역 번호의 경우 (예: `02-xxxx-xxxx`)
  if (numbers.length <= 2) {
    return numbers;
  }
  if (numbers.length <= 5) {
    return `${numbers.slice(0, 2)}-${numbers.slice(2)}`;
  }
  return `${numbers.slice(0, 2)}-${numbers.slice(2, 5)}-${numbers.slice(5)}`;
};
