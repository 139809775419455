const topLevelAdmin = (name: string) => {
  return {
    Main: {
      url: (page: number = 1, keyword: string = "") =>
        `/admin/${name}?page=${page}${keyword}`,
      pathName: `/${name}/*`,
      key: name,
    },

    ReportList: {
      url: `/admin/${name}/ReportList/`,
      pathName: `ReportList/*`,
      key: `${name}ReportList`,
    },

    Detail: {
      url: (id: string | number) => `/admin/${name}/detail/${id}`,
      pathName: "detail/:id",
      key: `${name}detail`,
    },
    Update: {
      url: (id: string | number) => `/admin/${name}/update/${id}`,
      pathName: "update/:id",
      key: `${name}update`,
    },
    Create: {
      url: `/admin/${name}/create/`,
      pathName: `create/`,
      key: `${name}create`,
    },
    BoardInfo: {
      url: (id: string | number) => `/admin/${name}/boardInfo/${id}`,
      pathName: "boardInfo/:id",
      key: `${name}boardInfo`,
    },
    UploadBanner: {
      url: (id: string | number) => `/admin/${name}/uploadBanner/${id}`,
      pathName: "uploadBanner/:id",
      key: `${name}uploadBanner`,
    },
  };
};

export const LPPUrls = {
  Auth: {
    Root: {
      url: "/auth",
      pathName: "/auth/*",
    },
    Login: {
      Main: {
        url: "/auth/adminLogin",
        pathName: "adminLogin",
      },
    },
    FindPassword: {
      url: "/auth/findPassword",
      pathName: "findPassword",
    },
    Agreement: {
      url: "/auth/agreement",
      pathName: "agreement",
    },
  },
  Admin: {
    // url:'/admin',
    // Landing: '/admin/adminAccount/',
    Root: {
      url: "/admin",
      pathName: "/admin/*",
    },
    MyPage: topLevelAdmin("MyPage"),

    /**상품관리 */

    // Product: topLevelAdmin("Product"),

    ProductCus: topLevelAdmin("ProductCus"),
    ProductBis: topLevelAdmin("ProductBis"),
    /** 판매관리 */

    /** DN 리셋팅 */

    /** 대시보드 */
    DashBoard: topLevelAdmin("DashBoard"),
    /** 사용자 관리 */
    Users: topLevelAdmin("Users"),
    /** 사운드 */
    Sounds: topLevelAdmin("Sounds"),
    /** 카테고리 */
    Category: {
      ...topLevelAdmin("Category"),
      CategorySounds: {
        url: (
          category: number | string,
          page: number = 1,
          keyword: string = ""
        ) => `/admin/Category/sounds/${category}?page=${page}${keyword}`,
        pathName: "sounds/:id",
        key: `CategorySounds`,
      },
    },

    CategorySounds: topLevelAdmin("CategorySounds"),
    /** 앨범 */
    Albums: topLevelAdmin("Albums"),
    /** 문의 관리 */
    InquiryManagement: topLevelAdmin("InquiryManagement"),
    /** 메세지 보내기 */
    Message: topLevelAdmin("Message"),
    /** 쿠폰/선물 */

    CouponCus: topLevelAdmin("CouponCus"),
    CouponBis: topLevelAdmin("CouponBis"),

    /** 정산/결제 */
    SettlementPaymentBis: topLevelAdmin("SettlementPaymentBis"),
    SettlementPaymentCus: topLevelAdmin("SettlementPaymentCus"),
    /** 배경테마 관리 */
    BackgroundThemeManagement: topLevelAdmin("BackgroundThemeManagement"),
    /** 참여진 관리 */
    ParticipantManagement: topLevelAdmin("ParticipantManagement"),
    /** 공지사항 관리 */
    Notice: topLevelAdmin("Notice"),
    /** 협업 회사 관리 */
    Collabotator: topLevelAdmin("Collabotator"),
  },
};
