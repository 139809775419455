import { Navigate, Route, Routes } from "react-router-dom";

import BisTablePage from "./BisTable";

export default function CouponBisRoot() {
  return (
    <Routes>
      <Route path={"/"} element={<BisTablePage />} />

      <Route path="/*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
}
